import PlayerSignInModal from "../components/PlayerSignInModal";
import { useGame } from "../auth/gameProvider";
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import banner from "../images/ama-banner-full.png";
import turtleavatar from "../images/turtleavatar.png";
import { useContext } from "react";
import carbon from "../images/carbon.png";
import "../styles/Lobby.css";
import { styled } from "@mui/material/styles";

import {
  Box,
  Collapse,
  Typography,
  List,
  ListItemText,
  Avatar,
  Grid,
  ListItem,
  ThemeProvider,
  createTheme,
  ListItemButton,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ReferralModal from "../components/ReferralModal";
import ModalComponent from "../components/DonateModalComponent";
import NewsletterModal from "../components/NewsletterModal";
import VolunteerModalLobby from "../components/VolunteerModalLobby";
import InstaModal from "../components/InstaModal";
import FeedbackModal from "../components/FeedbackModal";
import RestorationModal from "../components/RestorationModal";
import Countdown from "../components/Countdown";
import SpinWheel from "../components/SpinWheel";

const participants = [
  {
    option: "Daisy F.",
    // style: { backgroundColor: "#4e2fd0", textColor: "white" },
  },
  {
    option: "richardivan@gmail.com",
    //style: { backgroundColor: "#6bb2df", textColor: "white" },
  },
  {
    option: "Anna K.",
    //style: { backgroundColor: "#31ac50", textColor: "white" },
  },
  {
    option: "richardivan@gmail.com",
    //style: { backgroundColor: "#495189", textColor: "white" },
  },
  {
    option: "Sophie M.",
    // style: { backgroundColor: "#9890dc", textColor: "white" },
  },
  {
    option: "richardivan@gmail.com",
    // style: { backgroundColor: "#9890dc", textColor: "white" },
  },
  {
    option: "Sophie M.",
    // style: { backgroundColor: "#9890dc", textColor: "white" },
  },

  {
    option: "richardivan@gmail.com",
    //style: { backgroundColor: "#495189", textColor: "white" },
  },
  {
    option: "Sophie M.",
    // style: { backgroundColor: "#9890dc", textColor: "white" },
  },
  {
    option: "Sophie M.",
    // style: { backgroundColor: "#9890dc", textColor: "white" },
  },
  {
    option: "Sophie M.",
    // style: { backgroundColor: "#9890dc", textColor: "white" },
  },
  {
    option: "richardivan@gmail.com",
    //style: { backgroundColor: "#495189", textColor: "white" },
  },
  {
    option: "Sophie M.",
    // style: { backgroundColor: "#9890dc", textColor: "white" },
  },

  // Only the first two participants for now
];
const getDynamicFontSize = (numPlayers, nameLength) => {
  // Larger font size for fewer players

  if (numPlayers <= 29) return "12";
  if (numPlayers <= 50) return "11";
  if (numPlayers <= 60) return "8";
  if (numPlayers <= 70) return "9";
  if (numPlayers <= 80) return "10";

  return "10"; // Smaller font size for many players
};

const colorArray = [
  "#4e2fd0", //Violet Blue
  "#6bb2df", //Light Blue
  "#31ac50", //green
  "#495189", // dark dusty blue
  "#9890dc", //lavender
  "#7d6f59", //donkey brown
  "#153211", //dark forest
  "#2f470c", //green
  "#a7e27b", //light green
];

const getRandomColor = () => {
  return colorArray[Math.floor(Math.random() * colorArray.length)];
};

const whiteText = () => "#FFFFFF";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontFamily: "Raleway, sans-serif", // Change to your desired font
    fontSize: "12px", // Optional: adjust font size
    backgroundColor: theme.palette.primary.main, // Optional: change the background color
    color: theme.palette.common.white, // Optional: change the text color
  },
}));

const theme = createTheme({
  palette: {
    customColor: {
      main: "#2a52be",
    },
  },
  typography: {
    button: {
      fontWeight: "bold",
      variant: "contained",
      marginBottom: "2%",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: "#2a52be",
          "&:hover": {
            backgroundColor: "#0000CD",
          },
        },
      },
    },
  },
});

const mediatheme = createTheme({
  breakpoints: {
    values: {
      xs: 310,
      sm: 571,
      md: 700,
      lg: 900,
      xl: 1050,
    },
  },
});

//Screen Adjustments

const Lobby = () => {
  const [referralModal, setReferralModal] = useState(false);
  const { player } = useGame();
  const navigate = useNavigate();
  const [isSubscribeModalOpen, setIsSubscribeModalOpen] = useState(false);
  const [players, setPlayers] = useState(participants);
  const [isNewsletterModalOpen, setIsNewsletterModalOpen] = useState(false);
  const [isVolunteerModalOpen, setIsVolunteerModalOpen] = useState(false);
  const [isInstaModalOpen, setIsInstaModalOpen] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [isRestorationOpen, setIsRestorationOpen] = useState(false);
  const [points, setPoints] = useState(player?.points ?? "0");
  const [level, setLevel] = useState(player?.level ?? "0");
  const [playerName, setPlayerName] = useState(player?.name ?? "Guest");
  const [playerColors, setPlayerColors] = useState([]);
  const [probability, setProbability] = useState(player?.probability ?? "TBD");
  const [displayChallenge, setDisplayChallenge] = useState();
  const upcomingGiveaways = [
    {
      start: "8/15/2024 22:00 UTC",
      end: "8/16/2024 10:00 UTC",
    },
    {
      start: "8/22/2024 22:00 UTC",
      end: "8/23/2024 10:00 UTC",
    },
    {
      start: "8/29/2024 22:00 UTC",
      end: "8/30/2024 10:00 UTC",
    },
    {
      start: "9/5/2024 22:00 UTC",
      end: "9/6/2024 10:00 UTC",
    },
    {
      start: "9/12/2024 22:00 UTC",
      end: "9/13/2024 10:00 UTC",
    },
    {
      start: "9/19/2024 22:00 UTC",
      end: "9/20/2024 10:00 UTC",
    },
    {
      start: "9/26/2024 22:00 UTC",
      end: "9/27/2024 10:00 UTC",
    },
  ];
  const [giveawayActive, setGiveawayActive] = useState(false);

  //sets the correct url
  useEffect(() => {
    if (player) {
      navigate(`/lobby`, { replace: true });
    }
  }, [player, navigate]);

  //live updates the points
  useEffect(() => {
    if (player) {
      const fetchPlayer = async () => {
        try {
          const response = await fetch(`/api/players/${player.id}`);
          if (!response.ok) {
            throw new Error("Unable to fetch player");
          }
          const playerData = await response.json();
          const playerTasks = playerData.tasks;
          setDisplayChallenge(playerTasks.some((task) => task.id === 2)); //#2 is the id for photo challenge
          setPoints(playerData.points);
          setLevel(playerData.level);
          setProbability(playerData.probability);
        } catch (error) {
          console.error("Error fetching player information:", error);
        }
      };

      fetchPlayer();

      // Set up interval to fetch player data every 30 seconds
      const intervalId = setInterval(fetchPlayer, 30000);

      // Clean up interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, []);

  //helper function to generate a random color for the roulette

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await fetch("/api/players");
        if (!response.ok) {
          throw new Error("Unable to fetch players");
        }
        const players = await response.json();
        const formattedPlayers = players.map((player) => {
          return {
            option: player.name,
            style: { textColor: "white" },
          };
        });
        setPlayers(formattedPlayers);
      } catch (error) {
        console.error("Error fetching players:", error);
      }
    };

    fetchPlayers();
  }, []);

  //!PICK THE WINNER
  const fetchWinner = async () => {
    try {
      const response = await fetch("/api/winners");
      if (!response.ok) {
        throw new Error("Unable to fetch winner");
      }
      const winners = await response.json();
      winners.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      const mostRecentWinner = winners[0];
      return mostRecentWinner.player.name;
    } catch (error) {
      console.error("Error fetching players:", error);
    }
  };

  const handleCloseModal = () => {
    setReferralModal(false);
  };

  const handleGiveawayStateChange = useCallback((isActive) => {
    setGiveawayActive(isActive);
  }, []);

  const handleSpinComplete = useCallback((winningPrizeNumber) => {
    // Handle any post-spin logic here
    console.log(`Spin completed. Winning prize number: ${winningPrizeNumber}`);
  }, []);

  useEffect(() => {
    const newColors = players.map(() => getRandomColor());
    setPlayerColors(newColors);
  }, [players]);

  // collapsable hovers on right side
  const [openItem, setOpenItem] = useState(null);
  const handleToggle = (item) => {
    setOpenItem(openItem === item ? null : item);
  };

  return (
    <ThemeProvider theme={mediatheme}>
      <div className="Home" role="main">
        <Box className="banner-container">
          <Box
            component="img"
            src={banner}
            alt="AMA Banner"
            className="home-banner"
            sx={{
              width: "100%",
            }}
          />
          <Box
            className="header-area "
            sx={{
              textAlign: "center",
              backgroundColor: "rgba(0, 0, 0, 0.6)",
              overflowY: { xs: "auto" },
              height: {
                xs: "75vh",
                sm: "70vh",
                md: "85vh",
                lg: "74vh",
                xl: "76vh",
              },

              marginTop: {
                xs: "2%",
              },
              borderRadius: "3%",
              width: {
                xs: "92vw",
                sm: "85vw",
                md: "80vw",
                lg: "75vw",
                xl: "89vw",
              },
              marginBottom: {
                xs: "35%",
                md: "19%",
                lg: "13%",
                xl: "8%",
              },
            }}
          >
            <Typography
              variant="h5"
              align="center"
              style={{ color: "white" }}
              fontWeight={"bold"}
              className="hd"
              sx={{
                width: "100%",
                textAlign: "center",

                backgroundColor: "rgba(0, 0, 0, 0.6)",
                borderRadius: "10%",
                padding: {
                  xs: "2%",
                  md: "0.5%",
                  xl: "0.3%",
                },
                fontFamily: "Archivo Black",
                fontSize: "1.7rem",
              }}
            >
              Welcome to the Ama Earth Group Sweepstakes!
            </Typography>
            <Box
              className="lobby-container"
              sx={{
                width: "100%",
                flexGrow: 1,
                ml: 0,
                mr: 0,
                mt: 2,
              }}
            >
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  xl={3}
                  sx={{
                    paddingTop: { sm: "5%" },
                    order: { xs: 3, sm: 3, xl: 1 },
                  }}
                  alignItems="flex-start"
                >
                  <Box>
                    <Typography
                      variant="h7"
                      component="h4"
                      sx={{
                        textAlign: "center",
                        color: "#afd3f7",
                        fontSize: {
                          xs: "1.2rem",
                          sm: "1.5rem",
                          md: "1.8rem",
                          lg: "1rem",
                          xl: "1.1rem",
                        },
                        fontFamily: "Archivo Black",
                      }}
                    >
                      Activity Board
                      <Box
                        component="span"
                        variant="h7"
                        sx={{
                          display: "block",

                          fontSize: {
                            xs: "0.8rem",
                            sm: "1rem",
                            md: "1.2rem",
                            lg: "0.8rem",
                            xl: "0.8rem",
                          },
                          color: "#a1adad",
                          fontWeight: "normal",
                        }}
                      >
                        ____ Complete Actions, Earn Points ____
                      </Box>
                    </Typography>
                  </Box>
                  <Grid display={"flex"} alignItems={"center"}>
                    <Grid item xs={6} sm={6} xl={6} className="task-lists">
                      <List
                        className="task-list"
                        sx={{
                          textAlign: "center",
                          fontSize: {
                            xs: "0.6rem",
                            lg: "0.8rem",
                            xl: "0.7rem",
                          },
                        }}
                      >
                        <ListItem className="task-item">
                          <ListItemButton
                            onClick={() =>
                              navigate("/signup", { replace: true })
                            }
                          >
                            <strong className="sub">
                              Register Website Profile
                            </strong>
                          </ListItemButton>
                        </ListItem>

                        <ListItem className="task-item">
                          <ListItemButton
                            onClick={() => setIsNewsletterModalOpen(true)}
                          >
                            <strong className="sub">
                              Sign up for Newsletter
                            </strong>
                          </ListItemButton>
                        </ListItem>
                        <ListItem className="task-item">
                          <ListItemButton
                            onClick={() => setIsInstaModalOpen(true)}
                          >
                            <strong className="sub">Instagram Comment Task</strong>
                          </ListItemButton>
                        </ListItem>

                        <ListItem className="task-item">
                          <ListItemButton
                            onClick={() => setIsFeedbackModalOpen(true)}
                          >
                            <strong className="sub">Feedback</strong>
                          </ListItemButton>
                        </ListItem>
                        <ListItem className="task-item">
                          <ListItemButton
                            onClick={() => setIsRestorationOpen(true)}
                          >
                            <strong className="sub">Restoration</strong>
                          </ListItemButton>
                        </ListItem>

                        <ListItem className="task-item">
                          <ListItemButton
                            disabled={displayChallenge}
                            sx={{
                              "&.Mui-disabled": {
                                color: "#FFFFFF",
                                opacity: 1,
                              },
                            }}
                            onClick={() =>
                              navigate("/lobby/upload-photo-challenge", {
                                replace: true,
                              })
                            }
                          >
                            <div className="photo-challenge">
                              <strong className="sub">
                                Photograph a Tree
                              </strong>
                              <p>
                                <strong className="sub">
                                  ( 1 photo maximum )
                                </strong>
                              </p>
                            </div>
                          </ListItemButton>
                        </ListItem>

                        <ListItem className="task-item">
                          <ListItemButton
                            onClick={() => setReferralModal(true)}
                          >
                            <strong className="sub">Refer a Friend</strong>
                          </ListItemButton>
                        </ListItem>

                        <ListItem className="task-item">
                          <ListItemButton
                            onClick={() => setIsVolunteerModalOpen(true)}
                          >
                            <strong className="sub">Volunteer</strong>
                          </ListItemButton>
                        </ListItem>

                        <ListItem className="task-item">
                          <ListItemButton
                            onClick={() => setIsSubscribeModalOpen(true)}
                          >
                            <strong className="sub">Subscribe Now!</strong>
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item xs={6} sm={6} lg={6} xl={6} className="points">
                      <List>
                        <ListItem
                          className="point-list sub"
                          alignItems={"center"}
                        >
                          <ListItemText
                            primary="1 point"
                            className="point-item "
                            primaryTypographyProps={{
                              style: {
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                display: "flex",
                                justifyContent: "center",
                              },
                            }}
                          />
                        </ListItem>
                        <ListItem className="point-list" alignItems={"center"}>
                          <ListItemText
                            primary="1 point"
                            className="point-item "
                            primaryTypographyProps={{
                              style: {
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                display: "flex",
                                justifyContent: "center",
                              },
                            }}
                          />
                        </ListItem>
                        <ListItem className="point-list" alignItems={"center"}>
                          <ListItemText
                            primary="1 point"
                            className="point-item"
                            primaryTypographyProps={{
                              style: {
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                display: "flex",
                                justifyContent: "center",
                              },
                            }}
                          />
                        </ListItem>
                        <ListItem className="point-list">
                          <ListItemText
                            primary="1 point"
                            className="point-item"
                            primaryTypographyProps={{
                              style: {
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                              },
                            }}
                          />
                        </ListItem>
                        <ListItem className="point-list">
                          <ListItemText
                            primary="1 point"
                            className="point-item"
                            primaryTypographyProps={{
                              style: {
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                              },
                            }}
                          />
                        </ListItem>
                        <List className="point-list">
                          <ListItemText
                            primary="2 points"
                            className="point-item"
                            primaryTypographyProps={{
                              style: {
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                whiteSpace: "normal",
                                textAlign: "center",
                              },
                            }}
                          />
                        </List>
                        <List className="point-list">
                          <ListItemText
                            primary="3 points"
                            className="point-item"
                            primaryTypographyProps={{
                              style: {
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                whiteSpace: "normal",
                                textAlign: "center",
                              },
                            }}
                          />
                        </List>
                        <List className="point-list">
                          <ListItemText
                            primary="4 points"
                            className="point-item"
                            primaryTypographyProps={{
                              style: {
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                whiteSpace: "normal",
                                textAlign: "center",
                              },
                            }}
                          />
                        </List>
                        <List className="point-list">
                          <ListItemText
                            primary="4 points"
                            className="point-item"
                            primaryTypographyProps={{
                              style: {
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                whiteSpace: "normal",
                                textAlign: "center",
                              },
                            }}
                          />
                        </List>
                      </List>
                    </Grid>
                    {/*
                    <Grid item xs={3} sm={3} lg={3} xl={3} className="win">
                      <List className="win-chance">
                        <List className="win-list">
                          <ListItemText
                            className="win-item"
                            primary="+2% win chance"
                            primaryTypographyProps={{
                              style: {
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                whiteSpace: "normal",
                              },
                            }}
                          />
                        </List>
                        
                        <List className="win-list">
                          <ListItemText
                            className="win-item"
                            primary="+2% win chance"
                            primaryTypographyProps={{
                              style: {
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                whiteSpace: "normal",
                              },
                            }}
                          />
                        </List>
                        <List className="win-list">
                          <ListItemText
                            className="win-item"
                            primary="+5% win chance"
                            primaryTypographyProps={{
                              style: {
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                whiteSpace: "normal",
                              },
                            }}
                          />
                        </List>
                        <List className="win-list">
                          <ListItemText
                            className="win-item"
                            primary="+5% win chance"
                            primaryTypographyProps={{
                              style: {
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                whiteSpace: "normal",
                              },
                            }}
                          />
                        </List>
                        <List className="win-list">
                          <ListItemText
                            className="win-item"
                            primary="+10% win chance"
                            primaryTypographyProps={{
                              style: {
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                whiteSpace: "normal",
                              },
                            }}
                          />
                        </List>
                        <List className="win-list">
                          <ListItemText
                            className="win-item"
                            primary="+10% win chance"
                            primaryTypographyProps={{
                              style: {
                                fontWeight: "bold",
                                fontSize: "0.8rem",
                                whiteSpace: "normal",
                              },
                            }}
                          />
                        </List> 
                      </List>
                    </Grid>
                        */}
                  </Grid>
                  <Grid sx={{ margin: "5%" }}>
                    <h4 className="tableTitle hd">CO₂ Offset Annual Summary</h4>
                    <Box
                      component="img"
                      src={carbon}
                      alt="carbon removed"
                      sx={{
                        width: "auto",
                        height: { xs: "auto", lg: "90px" },
                        maxWidth: "100%", 
                        margin: "3%",
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  xl={6}
                  align="center"
                  sx={{ order: { xs: 2, sm: 2, xl: 2 } }}
                >
                  <Box
                    className="giveaway-area"
                    sx={{
                      width: {
                        xs: "100%",
                      },
                      textAlign: "center",
                      backgroundColor: "rgba(0, 0, 0, 0.6)",
                      padding: {
                        xs: "15px",
                        xl: "1px",
                      },
                      paddingBottom: {
                        xs: "3%",
                      },
                      borderRadius: "8px",
                      marginTop: "1px",
                      marginBottom: "10px", 
                    }}
                  >
                    <Countdown
                      upcomingGiveaways={upcomingGiveaways}
                      onGiveawayStateChange={handleGiveawayStateChange}
                    />
                  </Box>
                  <SpinWheel
                    players={players}
                    giveawayActive={giveawayActive}
                    onSpinComplete={handleSpinComplete}
                    playerColors={playerColors}
                    fetchWinner={fetchWinner}
                    upcomingGiveaways={upcomingGiveaways}
                  />
                  <Box
                    className="giveaway-area"
                    sx={{
                      width: {
                        xs: "100%",
                      },
                      textAlign: "center",
                      backgroundColor: "rgba(0, 0, 0, 0.6)",
                      padding: {
                        xs: "15px",
                        xl: "3px",
                      },
                      paddingBottom: {
                        xs: "3%",
                      },
                      borderRadius: "8px",
                      marginTop: "1px",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      className="monthlygiveaway"
                      sx={{
                        fontSize: { xs: "0.7rem", md: "1rem" },
                        margin: {
                          xs: "1%",
                        },
                      }}
                    >
                      <strong className="hd">
                        Giveaway Grand Prize:
                        <span
                          style={{
                            color: "rgb(48, 166, 93)",
                          }}
                        >
                          {" "}
                          <a
                            href="https://www.giftcards.com/us/en/catalog/product-details/visa-egift-gift-card"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "rgb(48, 166, 93)" }}
                          >
                            $50.00 Visa Gift Card
                          </a>
                        </span>
                      </strong>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: ".8rem",
                          color: "#AFD3F7",
                          marginTop: "4px",
                          margin: "auto",
                          background: "linear-gradient(135deg, rgba(81, 88, 113, 0.85) 30%, rgba(107, 114, 142, 0.85) 70%, rgba(81, 88, 113, 0.85))", 
                          border: "1px solid rgba(107, 114, 142, 0.85)", 
                          padding: "1%",
                          borderRadius: "10px",
                          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",  
                          textAlign: "center",
                        }}
                      >
                        90% of all proceeds go to climate action.
                      </Typography>
                    </Typography>
                    <Typography
                      variant="h6"
                      className="monthlygiveaway"
                      sx={{
                        fontSize: { xs: "0.8rem", md: "1rem" },
                        margin: {
                          xs: "1%",
                        },
                      }}
                    >
                      <strong>
                        {/* Weekly Prize:
                        <span
                          style={{
                            color: "rgb(48, 166, 93)",
                          }}
                        >
                          {" "}
                          <a
                            href="https://a.co/d/5wNHjm1"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "rgb(48, 166, 93)" }}
                          >
                            Stainless Steel Water Bottle
                        </span> </a>*/}
                      </strong>
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  xl={3}
                  alignContent={"center"}
                  sx={{ order: { xs: 1, sm: 1, xl: 3 } }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#000000",
                      padding: 1.2,
                      borderRadius: 1,
                      border: "1px solid rgb(48, 166, 93)",
                      width: {
                        xs: "70%",
                      },
                      marginX: "auto",
                      marginBottom: "10px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <Avatar
                      sx={{
                        width: { xs: "80px", sm: "100px", md: "120px" },
                        height: { xs: "80px", sm: "100px", md: "120px" },
                        marginBottom: 1,
                      }}
                      alt="avatar-placeholder"
                      src={turtleavatar}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,

                        left: "50%",
                        transform: "translateX(-50%)",
                        color: "orange",
                        bgcolor: "rgba(0, 0, 0, 0.5)",
                        borderRadius: 1,
                        width: "100%",
                        textAlign: "center",
                        fontWeight: "bold",
                        pb: 1,
                      }}
                    >
                      <span className="sub">{playerName}</span>
                    </Box>
                  </Box>
                  
                  {/* collapsable starts here */}
                  <Box
                    sx={{
                      backgroundColor: "#000000",
                      padding: 1.2,
                      borderRadius: 1,
                      border: "1px solid rgb(48, 166, 93)",
                      width: {
                        xs: "70%",
                      },
                      marginX: "auto",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      align="center"
                      style={{ color: "white" }}
                      sx={{
                        fontWeight: {
                          xs: "bold",
                        },
                        fontSize: {
                          xs: "1rem",
                        },
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center", 
                      }}
                    >
                      Level: {level}
                      <span
                        className="material-symbols-outlined"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleToggle("level")}
                      >
                        info
                      </span>
                    </Typography>
                    <Collapse in={openItem === "level"}>
                      <Typography
                        variant="body2"
                        align="center"
                        style={{ color: "rgb(48, 166, 93)" }}
                      >
                        <br />
                        Level 1 = 2 Points <br />
                        Level 2 = 5 Points <br />
                        Level 3 = 7 Points <br />
                        Level 4 = 10 Points <br />
                        Level 5 = 15 Points <br />
                        Level 6 = 20 Points <br />
                        Level 7 = 25 Points <br />
                        Level 8 = 30 Points <br />
                        Level 9 = 35 Points <br />
                        Level 10 = 40 Points
                      </Typography>
                    </Collapse>
                  </Box>

                  <Box
                    sx={{
                      backgroundColor: "#000000",
                      padding: 1.2,
                      borderRadius: 1,
                      border: "1px solid rgb(48, 166, 93)",
                      width: {
                        xs: "70%",
                      },
                      marginX: "auto",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      align="center"
                      style={{ color: "white" }}
                      sx={{
                        fontWeight: {
                          xs: "bold",
                        },
                        fontSize: {
                          xs: "1rem",
                        },
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center", 
                      }}
                    >
                      Points: {points}
                      <span
                        className="material-symbols-outlined"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleToggle("points")}
                      >
                        info
                      </span>
                    </Typography>
                    <Collapse in={openItem === "points"}>
                      <Typography
                        variant="body2"
                        align="center"
                        style={{ color: "rgb(48, 166, 93)" }}
                      >
                        This is your point total based on the number of tasks that you completed on our activity board. Complete more tasks to raise your points and probability to win our giveaways!
                      </Typography>
                    </Collapse>
                  </Box>

                  <Box
                    sx={{
                      backgroundColor: "#000000",
                      padding: 1.2,
                      borderRadius: 1,
                      border: "1px solid rgb(48, 166, 93)",
                      width: {
                        xs: "70%",
                      },
                      marginX: "auto",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      align="center"
                      style={{ color: "white" }}
                      sx={{
                        fontWeight: {
                          xs: "bold",
                        },
                        fontSize: {
                          xs: "1rem",
                        },
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center", 
                      }}
                    >
                      Win Probability: {probability}% 
                      <span
                        className="material-symbols-outlined"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleToggle("probability")}
                      >
                        info
                      </span>
                    </Typography>
                    <Collapse in={openItem === "probability"}>
                      <Typography
                        variant="body2"
                        align="center"
                        style={{ color: "rgb(48, 166, 93)" }}
                      >
                        A player's probability of winning is based on the total points they have accumulated from completed tasks relative to the total points of all players. The calculation starts by summing the points from all completed tasks across all players to determine the total points in the system. Next, it calculates the player's points from their own completed tasks. The player's win probability is then derived by dividing their points by the total points and multiplying by 100 to get a percentage, which is rounded to the nearest whole number.
                      </Typography>
                    </Collapse>
                  </Box>
                  {/* collapsable ends here */}
                  
                  <Box
                    sx={{
                      backgroundColor: "#000000",
                      padding: 1.2,

                      width: {
                        xs: "70%",
                      },
                      marginX: "auto",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      align="left"
                      style={{
                        color: "aliceblue",
                        fontWeight: "bold",
                        fontSize: "0.7rem",
                      }}
                    >
                      <span className="sub">
                        {" "}
                        NOTE: Level up by completing tasks from the activity
                        board🔓
                      </span>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {referralModal && (
            <ReferralModal onClose={handleCloseModal} playerId={player.id} />
          )}
          {!player && (
            <div className="lobby-overlay">
              <PlayerSignInModal />
            </div>
          )}
          {isSubscribeModalOpen && (
            <ModalComponent onClose={() => setIsSubscribeModalOpen(false)} />
          )}
          {isNewsletterModalOpen && (
            <NewsletterModal
              onClose={() => setIsNewsletterModalOpen(false)}
              player={player}
            />
          )}
          {isVolunteerModalOpen && (
            <VolunteerModalLobby
              onClose={() => setIsVolunteerModalOpen(false)}
            />
          )}
          {isInstaModalOpen && (
            <InstaModal onClose={() => setIsInstaModalOpen(false)} />
          )}
          {isFeedbackModalOpen && (
            <FeedbackModal onClose={() => setIsFeedbackModalOpen(false)} />
          )}
          {isRestorationOpen && (
            <RestorationModal onClose={() => setIsRestorationOpen(false)} />
          )}
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default Lobby;
